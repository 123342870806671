import type { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Layout from 'components/common/Layout'
import Grid from 'components/common/Grid'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import Button from 'components/common/Button'
import IconHome from 'icons/s-home.svg'

type Locale = {
  locale: string
}

export async function getStaticProps({ locale }: Locale) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

const Error: NextPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <div className="container mb-20 lg:mb-30">
        <Grid>
          <div className="col-span-full row-start-1 relative z-[2] text-white p-4 mb-39 flex sm:mb-65 lg:mb-0 3xl:col-start-2 3xl:col-span-10 3xl:p-0">
            <div className="m-auto w-full">
              <h1 className="font-bold text-[16.8vw] 3xl:text-[234px] leading-none mb-2">
                {t('404.title')}
              </h1>
              <p className="font-medium text-xl sm:text-2xl">
                {t('404.subline')}
              </p>
              <p className="text-md mb-5 sm:text-lg">{t('404.text')}</p>
              <Link href="/" passHref>
                <Button variant="small" as="div">
                  <IconHome className="stroke-1.5 stroke-current" />
                  <span className="ml-2">{t('404.link')}</span>
                </Button>
              </Link>
            </div>
          </div>
          <div className="col-span-full row-start-1">
            <div className="grid h-[620px] sm:h-[720px] 2xl:h-[840px] grid-cols-12 grid-rows-8 bg-pink-100 rounded-xl md:rounded-2xl overflow-hidden -mx-2 sm:-mx-4 lg:-mx-6 2xl:-mx-12.5">
              <div className="row-start-7 sm:row-start-6 row-end-[9] col-start-1 col-end-8 lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:row-end-6">
                <img
                  className="mx-auto mb-10 w-full h-full object-cover"
                  src="/img/404-mood-image.jpg"
                  alt=""
                />
              </div>
              <div className="row-start-7 sm:row-start-6 row-end-[9] col-start-8 col-end-13 bg-[#ffbebe]">
                <img
                  className="mx-auto mb-10 w-full h-full object-contain"
                  src="/img/404-error-illu.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </Layout>
  )
}

export default Error
